@import url("https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined");
@import "assets/css/_colors";
@import "assets/css/_fonts";
* {
  margin: 0;
  padding: 0;
  &:focus {
    outline: none;
  }
}
a:hover {
  text-decoration: none;
}
app-login {
  padding: 3% 0;
  background-color: $color-white;
  width: 100%;
  height: auto;
}
.sv-main-content {
  width: 100% !important;
  min-height: 87vh !important;
  background-color: $color-light !important;
}

.sv-w-100 {
  width: 100%;
}
app-conversations {
  padding: 3% 0;
  background-color: $color-light;
  width: 500px;
  height: 100vh;
}

app-loading {
  width: 100vw;
  height: 100vh;
  background-color: rgba($color: $color-black, $alpha: 0.9);
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* FONTS */
.sv-open-sans {
  font-family: "Open Sans";
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
}
.sv-bold {
  font-weight: bold;
}
.sv-semi-bold {
  font-weight: 600;
}
/* FONTS */

/* FONT SIZE */
.sv-size-40 {
  font-size: 40px;
}
.sv-size-28 {
  font-size: 28px;
}
.sv-size-24 {
  font-size: 24px;
}
.sv-size-22 {
  font-size: 22px;
}
.sv-size-20 {
  font-size: 20px;
}
.sv-size-18 {
  font-size: 18px;
}
.sv-size-16 {
  font-size: 16px;
}
.sv-size-14 {
  font-size: 14px;
}
.sv-size-13 {
  font-size: 13px;
}
.sv-size-12 {
  font-size: 12px;
}
.sv-size-10 {
  font-size: 10px;
}
/* END FONT SIZE */

/* DISPLAY */
.sv-row {
  display: flex !important;
  flex-direction: row !important;
}
.sv-column {
  display: flex !important;
  flex-direction: column !important;
}
.sv-justify-between {
  justify-content: space-between !important;
}
.sv-justify-around {
  justify-content: space-around !important;
}
.sv-justify-end {
  justify-content: flex-end !important;
}
.sv-justify-center {
  justify-content: center !important;
}
.sv-align-center {
  align-items: center !important;
}
.sv-align-end {
  align-items: flex-end !important;
}
.sv-align-start {
  align-items: flex-start !important;
}
/* END DISPLAY */
/* COLORS */
.sv-white {
  color: $color-white;
}
.sv-light-grey {
  color: $color-light-grey;
}
.sv-light {
  color: $color-light;
}
.sv-grey {
  color: $color-grey;
}
.sv-medium-grey {
  color: $color-medium-grey;
}
.sv-dark-grey {
  color: $color-dark-grey;
}
.sv-black {
  color: $color-black;
}
.sv-p-light {
  color: $color-p-light;
}
.sv-primary {
  color: $color-primary;
}
.sv-primary-2 {
  color: $color-primary-2;
}
.sv-p-dark {
  color: $color-p-dark;
}
.sv-green {
  color: $color-green;
}
.sv-yellow {
  color: $color-yellow;
}
/* END COLORS */

/** TEXT DECORATION **/
.sv-underline {
  text-decoration: underline;
}
.sv-text-center {
  text-align: center;
}
/** TEXT DECORATION **/

/* BACKGRUND COLORS */
.sv-bg-primary {
  background-color: $color-primary;
}
.sv-bg-light-gray {
  background-color: $color-light-grey;
}
.sv-bg-light {
  background-color: $color-light;
}
.sv-bg-white {
  background-color: $color-white;
}
.sv-bg-yellow {
  background-color: $color-yellow;
}
.sv-bg-blue {
  background-color: $color-blue;
}
/* END BACKGRUND COLORS */
/* INPUT */
.mat-form-field-label-wrapper {
  .mat-form-field-label {
    position: initial;
    height: 100%;
    align-items: center;
    display: flex !important;
    font-size: 14px;
    padding-left: 8px;
    font-family: "Open Sans", sans-serif !important;
  }
}
.mat-form-field-appearance-legacy {
  div.mat-form-field-underline {
    height: 0px;
    display: none;
  }
  .mat-form-field-wrapper {
    padding: 0px;
  }
  .mat-form-field-infix {
    padding: 0 !important;
    border: none;
    display: flex;
    align-items: center;
  }
}
.mat-form-field-infix {
  width: 208px !important;
}
mat-form-field {
  font-size: 14px;
  width: -moz-available;
  width: -webkit-fill-available;
  margin-bottom: 16px;
  position: relative;
  input.mat-input-element,
  mat-select {
    width: -webkit-fill-available;
    height: 38px;
    border-radius: 3px;
    border: 1px solid $color-grey !important;
    font-size: 14px;
    color: $color-dark-grey !important;
    padding: 4px 26px 4px 8px;
    background-color: $color-light;
  }
  textarea.mat-input-element,
  mat-select {
    width: -webkit-fill-available;
    border-radius: 3px;
    border: 1px solid $color-grey !important;
    font-size: 14px;
    color: $color-dark-grey !important;
    padding: 4px 26px 4px 8px;
    background-color: $color-light;
  }
  .mat-error {
    display: block;
    padding-top: 15px;
    font-size: 10px;
    color: $color-p-light !important;
  }
  mat-icon {
    position: absolute;
    top: 15px;
    right: 16px;
  }
  &.ng-dirty:not(form) {
    label {
      color: $color-medium-grey !important;
    }
    input.mat-input-element,
    mat-select {
      border: 1px solid $color-grey;
      color: $color-dark-grey !important;
    }
    :disabled {
      background-color: $color-light-grey !important;
      border: 1px solid $color-light-grey !important;
    }
    textarea.mat-input-element {
      border: 1px solid $color-grey !important;
      color: $color-dark-grey !important;
    }
    textarea {
      border: 1px solid $color-grey !important;
      color: $color-dark-grey !important;
    }
    .mat-form-field-label-wrapper {
      padding-top: 20px !important;
    }
  }

  &.mat-focused,
  &.mat-focused.ng-dirty:not(form) {
    input.mat-input-element,
    textarea,
    mat-select {
      border: 1px solid $color-medium-grey !important;
      color: $color-dark-grey !important;
    }
    .mat-form-field-label-wrapper {
      padding-top: 20px !important;
    }
  }
  &.mat-form-field-invalid,
  &.mat-form-field-appearance-legacy.ng-dirty:not(form).ng-invalid:not(form) {
    label {
      color: $color-p-light !important;
    }
    input.mat-input-element {
      border: 1px solid $color-p-light !important;
    }
    textarea {
      border: 1px solid $color-p-light !important;
    }
    mat-select {
      border: 1px solid $color-p-light !important;
    }
  }
  &.mat-form-field-disabled {
    opacity: 0.4;
  }
  &.sv-fieled-text-area {
    textarea {
      padding-top: 24px !important;
    }
    .mat-form-field-label {
      align-items: baseline !important;
    }
    &.mat-form-field-should-float {
      .mat-form-field-label {
        transform: translateY(0) scale(0.75) perspective(94px) translateZ(3.001px) !important;
      }
    }
  }
  &.mat-focused,
  &.mat-focused.ng-dirty:not(form) {
    label {
      color: $color-medium-grey !important;
    }
  }
}
.sv-no-label {
  mat-label {
    padding-left: 4px;
  }
  &.mat-form-field-should-float {
    .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix span .mat-form-field-label {
      display: none !important;
    }
  }
}
/* END INPUT */
/* BUTTONS */
.mat-flat-button {
  font-size: 14px !important;
  font-family: "Open Sans" !important;
  font-weight: 600 !important;
  border-radius: 10px !important;
  &:disabled {
    background-color: rgba($color: $color-primary, $alpha: 0.7) !important;
    color: $color-white !important;
  }
  &.mat-light {
    color: $color-dark-grey !important;
    background-color: $color-light-grey !important;
  }
  &.mat-transparent {
    background-color: transparent !important;
  }
}
.mat-mini-fab {
  width: 34px !important;
  height: 34px !important;
  line-height: 0 !important;
  .mat-button-wrapper {
    padding: 0 !important;
  }
  &.mat-transparent {
    display: flex;
    justify-content: center;
    background-color: transparent !important;
  }
}
.sv-pointer {
  cursor: pointer;
}
/* END BUTTONS */
/* WIDTH */
.sv-w-60 {
  width: 60% !important;
}
.sv-w-50 {
  width: 50% !important;
}
.sv-w-53 {
  width: 53% !important;
}

.sv-w-20 {
  width: 20% !important;
}
/* END WIDTH */

/* PADDING */
.sv-padding-x-40 {
  padding: 0 40px;
}
/* END PADDING */

/* ICON */
.sv-icon-white {
  svg {
    path {
      fill: $color-white;
    }
  }
}
/* END ICON */

/* MATERIAL COMPONENTS */
.mat-select {
  padding: 4px 16px 4px 8px;
  font-family: "Open Sans";
}
.mat-option {
  color: $color-dark-grey !important;
  font-family: "Open Sans" !important;
}
.mat-select-trigger {
  height: 38px;
}
.mat-select-value {
  color: $color-dark-grey !important;
  padding-left: 12px !important;
  vertical-align: middle !important;
}
.mat-mini-fab {
  box-shadow: none !important;
}
.mat-dialog-container {
  padding: 16px 24px !important;
}
.cdk-overlay-dark-backdrop {
  background: rgba(77, 77, 77, 0.85);
  backdrop-filter: blur(10px);
}

.mat-snack-bar-container {
  color: white;
  margin-top: 80px !important;
  text-align: center !important;
  background-color: $color-primary !important;
}
.mat-dialog-container {
  padding: 0 !important;
}
.mat-checkbox {
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $color-dark-grey;
}
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: $color-green;
}
/* END MATERIAL COMPONENTS */

/* MARGIN */
.sv-mx-10 {
  margin: 0 10px;
}
.sv-mx-8 {
  margin: 0 8px;
}
.sv-my-10 {
  margin: 10px 0;
}
.sv-my-8 {
  margin: 8px 0;
}
.sv-margin-auto {
  margin: 0 auto;
}
.sv-mr-14 {
  margin-right: 14px;
}
.sv-mr-8 {
  margin-right: 8px !important;
}
.sv-mt-16 {
  margin-top: 16px !important;
}
.sv-mt-8 {
  margin-top: 8px !important;
}
.sv-mt-24 {
  margin-top: 24px;
}
.sv-ml-24 {
  margin-left: 24px;
}
.sv-ml-16 {
  margin-left: 16px !important;
}
.sv-mb-10 {
  margin-bottom: 10px;
}
/* END MARGIN */

.mat-select-arrow {
  color: $color-dark-grey !important;
}
.mat-flat-button.mat-accent,
.mat-raised-button.mat-accent,
.mat-fab.mat-accent,
.mat-mini-fab.mat-accent {
  background-color: #ffffff !important;
}

/* TABLE */
table {
  width: 100%;
  margin-top: 16px;
  font-family: "Open Sans" !important;
  thead {
    .mat-header-row {
      height: 48px;
      background-color: $color-light-grey;
      color: $color-dark-grey;
      th {
        border-bottom-width: 0px !important;
        font-size: 14px !important;
        font-weight: 600 !important;
      }
    }
  }
  tbody {
    tr:nth-child(even) {
      background-color: $color-light;
    }
    tr {
      td {
        font-size: 14px !important;
        color: $color-dark-grey !important;
        display: table-cell;
      }
      td.mat-cell,
      th.mat-header-cell {
        border-bottom-width: 0px !important;
        border-bottom-style: none !important;
      }
    }
    tr:hover {
      background-color: rgba($color: $color-p-light, $alpha: 0.1);
    }
  }
  .mat-header-cell {
    color: $color-dark-grey !important;
  }
}
/* END TABLE */
.sv-selected-photo {
  .mat-checkbox-inner-container {
    margin-top: 4px;
    margin-left: 4px;
  }
  .mat-checkbox-frame {
    border-radius: 15px;
    border-color: $color-white;
  }
  .mat-checkbox-background,
  .mat-checkbox-frame {
    border-radius: 15px;
  }
}
.sv-client-info {
  .mat-checkbox-frame {
    border-radius: 15px;
  }
  .mat-checkbox-background,
  .mat-checkbox-frame {
    border-radius: 15px;
  }
}
.sv-search-content {
  width: 351px;
  height: 40px;
  display: flex;
  padding: 0 5px;
  align-items: center;
  flex-direction: row;
  border-radius: 6px;
  border: 1px solid $color-grey;
  justify-content: space-between;
  .sv-input-search {
    font-weight: 600;
    font-family: "Open Sans";
    border: 1px solid transparent;
  }
}
.sv-input-container {
  width: 40px;
  height: 40px;
  margin-right: 8px;
  .sv-input-hidden {
    width: 0px;
    height: 0px;
    margin: 0;
    padding: 0;
  }
}
