@use "@angular/material" as mat;
$color-white: #ffffff;
$color-light-grey: #f0f0f0;
$color-light: #f9f9f9;
$color-grey: #c7c7c7;
$color-medium-grey: #838383;
$color-dark-grey: #4d4d4d;
$color-black: #212121;
$color-p-light: #ef5967;
$color-primary: #b71234;
$color-primary-2: #b7223d;
$color-p-dark: #800017;
$color-green: #27ae60;
$color-yellow: #ffad00;
$color-blue: #abe2ff;
$md-primary: (
  50: #f6e3e7,
  100: #e9b8c2,
  200: #db899a,
  300: #cd5971,
  400: #c23652,
  500: #b71234,
  600: #b0102f,
  700: #a70d27,
  800: #9f0a21,
  900: #900515,
  A100: #ffbdc1,
  A200: #ff8a92,
  A400: #ff5762,
  A700: #ff3d4a,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #ffffff,
  ),
);
$md-accent: (
  50: #fff5e0,
  100: #ffe6b3,
  200: #ffd680,
  300: #ffc64d,
  400: #ffb926,
  500: #ffad00,
  600: #ffa600,
  700: #ff9c00,
  800: #ff9300,
  900: #ff8300,
  A100: #ffffff,
  A200: #fff8f2,
  A400: #ffddbf,
  A700: #ffcfa6,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);
/* CUSTOM THEME */
@include mat.core();
$app-primary: mat.define-palette($md-primary);
$app-accent: mat.define-palette($md-accent);
$app-theme: mat.define-light-theme($app-primary, $app-accent);
@include mat.all-component-themes($app-theme);
/* END CUSTOM THEME */
